import React from 'react'
import Swal from "sweetalert2";
import Moment from 'react-moment';
import 'moment-timezone';
import ReactDOMServer from 'react-dom/server';
import { GameType } from "../utils/const";

export const joinDialog = ({ challengeName, challengeNumber, startTime, gameType, holes, additionalText, additionalText2, fee, pricePot, prices, signup }) => {
    Swal.fire({
        background: '#FFFFFF',
        html:
            '<br /><h1 class="alert-title" style="color:#000; font-size:20px;">Welcome to ' + challengeNumber + '<br /><small>' + holes + '-hole ' + gameType + ' on ' + getFormatedDate({ startTime }) +'</small></h1>' +
            additionalText +
            '<div class="reward-cost-container">' +
            prices +
            '</div>' + 
            additionalText2 ,
        showCancelButton: true,
        confirmButtonColor: '#8FC863',
        cancelButtonColor: '#DFDDD6',
        confirmButtonText: 'Enter for ' + fee,
        backdrop: 'rgba(0,0,0,0.8)',
        onOpen: () => {
            // code
        }
    }).then((result) => {
        if (result.value) {
            signup();
        }
    });

}

export const goToRegisterDialog = ({ challengeName, challengeNumber, startTime, gameType, holes, additionalText, additionalText2, fee, pricePot, prices, goToRegister }) => {
    Swal.fire({
        background: '#FFFFFF',
        html:
            '<br /><h1 class="alert-title" style="color:#000; font-size:24px;">Welcome to ' + challengeNumber + '<br /><small>' + holes + '-hole ' + gameType + ' on ' + getFormatedDate({ startTime }) + '</small></h1>' +
            additionalText +
            '<div class="reward-cost-container">' +
            prices +
            '</div>' + 
            additionalText2 ,
        showCancelButton: true,
        confirmButtonColor: '#8FC863',
        cancelButtonColor: '#DFDDD6',
        confirmButtonText: 'Register',
        backdrop: 'rgba(0,0,0,0.8)',
        onOpen: () => {
            // code
        }
    }).then((result) => {
        if (result.value) {
           goToRegister();
        }
    });
}

export const deleteDialog = ({ deleteAction }) => {
    Swal.fire({
        title: "Are you sure you want to delete the challenge?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#8FC863',
        cancelButtonColor: '#DFDDD6',
        confirmButtonText: 'Yes'
    }).then((result2) => {
        if (result2.value) {
            deleteAction();
        }
    })

}

export const scoreDialog = ({ reportScore, challengeId, activeChallenge }) => {
    var isStrokePlay = false;
    if (activeChallenge.gameType === GameType.STABLEFORD_NET) {
        var htmlGameType = '<h4 class="alert-title" style="color:#000;"><br />Enter your Net Stableford score</h4> ' + '<input id="swal-input1" class="swal2-input" style="width:90%;margin-left:5%;">';
    }
    else if (activeChallenge.gameType === GameType.STABLEFORD) {
        var htmlGameType = '<h4 class="alert-title" style="color:#000;"><br />Enter your Stableford score</h4> ' + '<input id="swal-input1" class="swal2-input" style="width:90%;margin-left:5%;">';
    }
    else if (activeChallenge.gameType === GameType.STROKE_PLAY) {
        var htmlGameType = '<h4 class="alert-title" style="color:#000;"><br />Register number of strokes</h4> ' + '<input id="swal-input1" class="swal2-input" style="width:90%;margin-left:5%;">';
        isStrokePlay = true;
    }
    else {
        var htmlGameType = '<h4 class="alert-title" style="color:#000;"><br />Register number of strokes</h4> ' + '<input id="swal-input1" class="swal2-input" style="width:90%;margin-left:5%;">';
        isStrokePlay = true;
    }
        Swal.fire({
            background: '#FFFFFF',
            html: htmlGameType,
            confirmButtonColor: '#8FC863',
            cancelButtonColor: '#DFDDD6',
            showCancelButton: true,
            confirmButtonText: 'Register',
            backdrop: 'rgba(0,0,0,0.8)',
            onOpen: () => {
                // code
            },
            preConfirm: () => {
                var score = document.getElementById('swal-input1').value;
                if (isNumeric(score)) {

                    if (isStrokePlay) {
                        if (score > 50)
                            return score;
                        else {
                            Swal.showValidationMessage(
                                `Strokes must be more than 50`
                            )
                        }
                    }
                    else {
                        if (score < 60 && score >=0)
                            return score;
                        else {
                            Swal.showValidationMessage(
                                `Score must be between 0 and 59`
                            )
                        }
                    }

                }
                else {
                    Swal.showValidationMessage(
                        `${score} is not a valid score`
                    )
                }


            }
        }).then((result) => {
            if (result.value) {
                console.log("scoreDialog:" + result.value);
                Swal.fire({
                    title: "Is " + result.value +" you correct score?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#8FC863',
                    cancelButtonColor: '#DFDDD6',
                    confirmButtonText: 'Yes'
                }).then((result2) => {
                    if (result2.value) {
                         reportScore(challengeId, result.value);
                    }
                })

            }
        });
}

export const infoDialog = () => {
        Swal.fire({
            background: '#FFFFFF',
            html:
                '<br /><h1 class="alert-title" style="color:#000; font-size:30px;">How to play</h1> ' +
                '<p><small>- Register to a challenge of your choice within your handicap or above.<br />- For <strong>Buy-In/Sponsored Challenges</strong>, play an official round of golf (counting for your hcp) in the same timeframe as your chosen challenge.<br />- For <strong>Free Challenges</strong>, official rounds are not mandatory (please no gimmes to make it fair with your co-competitors).<br />- <b>Enter your score in your Lobby</b> at the end of your round (same day).<br />- You may be asked to provide your official score via email (National Federation or Golf Club) to testify the authenticity of your score for Buy-In/Sponsored Challenges.</small></p>' +
                '<div class="reward-cost-container">' +
                '</div>' + 
                '<iframe width="84%" height="242" src="https://www.youtube.com/embed/tGneBkxsPAM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' ,
                // '<video width="84%" height="240" controls><source src="/images/video-wgc-howtoplay.mp4" type="video/mp4"><source src="/images/video-wgc-howtoplay.webm" type="video/webm"></video>' ,
            showCancelButton: false,
            confirmButtonColor: '#8FC863',
            confirmButtonText: 'Ok',
            backdrop: 'rgba(0,0,0,0.8)',
            onOpen: () => {
                // code
            }
        }).then((result) => {

        });
}

export const infoDialogPro = () => {
    Swal.fire({
        background: '#FFFFFF',
        html:
            '<br /><h1 class="alert-title" style="color:#000; font-size:30px;">How to play PRO</h1> ' +
            '<p><small>—> Register on wgc.gg (menu)</small></p>' +
            '<p><small>—> Deposit $ via PayPal (menu)</small></p>' +
            '<p><small>—> Join the Challenge of your choice before 9am UCT+1.</small></p>' +
            '<p><small>—> Play well out there, score low!</small></p>' +
            '<p><small>—> Enter your score in Lobby (green box) before 9pm UCT+1</small></p>' +
            '<p><small>—> Check your profile for playing history</small></p>' +
            '<p><small>More info in <a href="/about">ABOUT</a>.</small></p>' +
            '<div class="reward-cost-container">' +
            '</div>',        
        showCancelButton: false,
        confirmButtonColor: '#8FC863',
        confirmButtonText: 'Ok',
        backdrop: 'rgba(0,0,0,0.8)',
        onOpen: () => {
            // code
        }
    }).then((result) => {

    });
}


export const specChallengeDialog = () => {
    Swal.fire({
        background: '#FFFFFF',
        html:
            '<br /><h1 class="alert-title" style="color:#000; font-size:30px;">Ryder Challenge 2021</h1> ' +
            '<p><small>Compete in Free Challenges to qualify among the 10 players to play the <strong>2021 Ryder Challenge</strong> taking place after September 26th.<br /><br />Best 3 results count! See your position on the Ryder Challenge Leaderboard displayed in the Leaderboard section.<br /><br />The winner of the Ryder Challenge will win a <strong>2021 Ryder Cup flag</strong> signed by participating players.<br /><br />Good luck and play well!',
        showCancelButton: false,
        confirmButtonColor: '#8FC863',
        confirmButtonText: 'Ok',
        backdrop: 'rgba(0,0,0,0.8)',
        onOpen: () => {
            // code
        }
    }).then((result) => {

    });
}

export const specChallengeFinalDialog = () => {
    Swal.fire({
        background: '#FFFFFF',
        html:
            '<br /><h1 class="alert-title" style="color:#000; font-size:30px;">Ryder Challenge Final 2021</h1> ' +
            '<p><strong>Congratulations, you won your spot in the Ryder Challenge!</strong><br /><br />You have automatically been added to the Ryder Challenge, just go play a Net Stableford round before Oct 5th, then enter your score in your lobby.<br /><br />If tied winners, best handicap wins.<br /><br />Good luck and have fun!',
        showCancelButton: false,
        confirmButtonColor: '#8FC863',
        confirmButtonText: 'Ok',
        backdrop: 'rgba(0,0,0,0.8)',
        onOpen: () => {
            // code
        }
    }).then((result) => {

    });
}

export const specChallengeOmegaDialog = () => {
    Swal.fire({
        background: '#FFFFFF',
        html:
            '<br /><h1 class="alert-title" style="color:#000; font-size:30px;">OMEGA Autumn Challenge</h1> ' +
            '<p><strong>Seasons do not stop World Golf Challenge!</strong><br /><br /><strong>Join the OMEGA Autumn to challenge yourself, your buddies and all the participants in your own handicap category.</strong><br /><br /><strong>Play a 18-hole round between Oct. 23rd and Nov. 7th.</strong><br /><br /><strong>Free, Friendly and International, this challenge will reward the best players with a nice OMEGA gift.</strong><br /><br /><strong>Play well!</strong>',
        showCancelButton: false,
        confirmButtonColor: '#8FC863',
        confirmButtonText: 'Ok',
        backdrop: 'rgba(0,0,0,0.8)',
        onOpen: () => {
            // code
        }
    }).then((result) => {

    });
}


export const specChallengeTrackmanDialog = () => {
    Swal.fire({
        background: '#FFFFFF',
        html:
            '<br /><h1 class="alert-title" style="color:#000; font-size:30px;">WGC Indoor Challenge</h1> ' +
            '<p><br/>Play front 9 of <a target="_blank" href="https://trackman.page.link/Fyqes">Valderrama Golf Club</a> in net stableford for free in any Trackman Center to win <a target="_blank" href="https://bunker-mentality.com/">Bunker Mentality</a> vouchers!<br/><br/>' +
            'Our privileged & suggested centers are<br/>' +
            'Sweden: <a target="_blank" href="https://ruffgolf.se">RUFF</a><br/>' +
            'Switzerland: <a target="_blank" href="https://www.golf-fit.ch">Golf-Fit Puidoux</a> & <a target="_blank" href="https://domagolf.com/">Doma Golf</a><br/><br/>' +           
            'In parallel, join a free <i>WGC Indoor Challenge</i>.<br/><br/>Play as much as you like, your best score count!<br/><br/>Available until Jan 31st.',
        showCancelButton: false,
        confirmButtonColor: '#8FC863',
        confirmButtonText: 'Ok',
        backdrop: 'rgba(0,0,0,0.8)',
        onOpen: () => {
            // code
        }
    }).then((result) => {

    });
}

export const specChallengeStensonDialog1 = () => {
    Swal.fire({
        background: '#FFFFFF',
        html:
            '<br /><h1 class="alert-title" style="color:#000; font-size:30px;">Henrik Stenson Eyewear Championship</h1> ' +
            '<p><br/><a target="_blank" href="https://henrikstensoneyewear.com/">Visit Henrik Stenson Eyewear</a>.<br /><br />In parallel, join a free <i>HS Championship Challenge</i>.<br /><br />Play as many rounds as you like to get your best 3 counting for your ranking.<br /><br />1 round per week days are available.<br/><br/>Available until November 6th!',
        showCancelButton: false,
        confirmButtonColor: '#8FC863',
        confirmButtonText: 'Ok',
        backdrop: 'rgba(0,0,0,0.8)',
        onOpen: () => {
            // code
        }
    }).then((result) => {

    });
}

export const specChallengeWeekendDialog = () => {
    Swal.fire({
        background: '#FFFFFF',
        html:
            '<br /><h1 class="alert-title" style="color:#000; font-size:30px;">Weekend Championship</h1> ' +
            '<p><br/>Join a $5 buy-in <i>Weekend Championship Challenge</i>.<br /><br />Play as many rounds as you like to get your best 2 counting for cash prizes<br /><br />Weekends only.<br/><br/>Available April 1st to May 31st',
        showCancelButton: false,
        confirmButtonColor: '#8FC863',
        confirmButtonText: 'Ok',
        backdrop: 'rgba(0,0,0,0.8)',
        onOpen: () => {
            // code
        }
    }).then((result) => {

    });
}

export const wgcTournamentDialog = () => {
    Swal.fire({
        background: '#FFFFFF',
        html:
            '<br /><h1 class="alert-title" style="color:#000; font-size:30px;">World Golf Challenge<br/>Tournament</h1> ' +
            '<br/>Register in your handicap category to this tournament played simultaneously in lots of different countries.<br /><br />No EDS allowed (extra day score). Your score and date must match an official tournament no matter where you play in the World<br/><br/>We encourage you to play a competition in our golf clubs partners offering the WGC Tournament. Other tournaments work too.<br/><br/><a href="/wgc-tournament">Click here for more information</a>',
        showCancelButton: false,
        confirmButtonColor: '#8FC863',
        confirmButtonText: 'Ok',
        backdrop: 'rgba(0,0,0,0.8)',
        onOpen: () => {
            // code
        }
    }).then((result) => {

    });
}


const getFormatedDate = ({ startTime }) => {


    return ReactDOMServer.renderToString(<Moment locale="en" date={startTime} format="MMMM Do" />);
}

function isNumeric(str) {

    // Check if input is string
    if (typeof str != "string")
        return false

    // Use type coercion to parse the _entirety_ of the string
    // (`parseFloat` alone does not do this).
    // Also ensure that the strings whitespaces fail
    return !isNaN(str) &&
        !isNaN(parseFloat(str))
}
