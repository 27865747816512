
export function subscribeToChallenges() {
    return { type: "SUBSCRIBE_CHALLENGES" };
}

export function lobbyInfo(challenge, userId) {
    return { type: "LOBBY_INFO", challenge: challenge, userId:userId };
}

export function lobbiesInfo(challenges, userId) {
    return { type: "LOBBIES_INFO", challenges: challenges, userId: userId };
}

export function lobbyNotFound() {
    return { type: "LOBBY_NOT_FOUND"};
}

export function availableChallenges(challenges) {
    return { type: "AVAILABLE_CHALLANGES", challenges: challenges };
}

export function availableSpecialChallenges(challenges) {
    return { type: "AVAILABLE_SPECIAL_CHALLANGES", challenges: challenges };
}

export function challengeUpdate(challengeUpdate, userId) {
    return { type: "CHALLENGE_UPDATE", challengeUpdate: challengeUpdate, userId: userId};
}

export function challengeRemoveWithDelay(id) {
    return { type: "CHALLENGE_REMOVE_WITH_DELAY", id: id };
}

export function challengeAdd(newChallenge) {
    return { type: "CHALLENGE_ADD", newChallenge: newChallenge };
}

export function goto(id) {
    console.log("Go to to challenge with id=" + id);
    return { type: "GO_TO", id: id };

}
export function signup(id, special) {
    console.log("Signup to challenge with id=" + id);
    return { type: "CHALLENGE_SIGNUP", id: id, special: special };
}

export function leave(id , special) {
    console.log("Leave challenge with id=" + id);
    return { type: "CHALLENGE_LEAVE", id: id, special: special };
}

export function closeLobby(id) {
    console.log("Close current lobby");
    return { type: "CLOSE_LOBBY", id: id};
}

export function closeStreamerLobby(id) {
    console.log("Close streamer lobby");
    return { type: "CLOSE_STREAMER_LOBBY", id: id };
}


export function subscribeToLobby(id) {
    console.log("Subscribe to lobby with id=" + id);
    return { type: "SUBSCRIBE_TO_LOBBY", id: id };
}

export function requestSubscriptionToStreamerLobby(nick) {
    console.log("Request subscription to streamer lobby with nick=" + nick);
    return { type: "REQUEST_SUBSCRIPTION_TO_STREAMER_LOBBY", nick: nick };
}

export function subscribeToStreamerLobby(nick) {
    console.log("Subscribe to streamer lobby with nick=" + nick);
    return { type: "SUBSCRIBE_TO_STREAMER_LOBBY", nick: nick };
}

export function joinedChallenge(joinedChallenge, userId) {
    return { type: "JOINED_CHALLENGE", joinedChallenge: joinedChallenge, userId: userId };
}

export function leftChallenge(leftChallenge, userId) {
    return { type: "LEFT_CHALLENGE", leftChallenge: leftChallenge, userId: userId };
}

export function challengeEvent(challengeEvent) {
    return { type: "CHALLENGE_EVENT", challengeEvent: challengeEvent };
}

export function streamerChallengeEvent(challengeEvent) {
    return { type: "STREAMER_CHALLENGE_EVENT", challengeEvent: challengeEvent };
}

export function challengeParticipantEvent(challengeParticipantEvent) {
    return { type: "CHALLENGE_PARTICIPANT_EVENT", challengeParticipantEvent: challengeParticipantEvent };
}

export function streamerChallengeParticipantEvent(challengeParticipantEvent) {
    return { type: "STREAMER_CHALLENGE_PARTICIPANT_EVENT", challengeParticipantEvent: challengeParticipantEvent };
}

export function challengeResult(challengeResult) {
    return { type: "CHALLENGE_RESULT", challengeResult: challengeResult };
}

export function streamerChallengeResult(challengeResult) {
    return { type: "STREAMER_CHALLENGE_RESULT", challengeResult: challengeResult };
}

export function challengeError(error) { 
    return { type: "CHALLENGE_ERROR", error: error };
}

export function streamerChallengeError(error) {
    return { type: "STREAMER_CHALLENGE_ERROR", error: error };
}

export function challengeParticipantError(error) {
    return { type: "CHALLENGE_PARTICIPANT_ERROR", error: error };
}

export function streamerChallengeParticipantError(error) {
    return { type: "STREAMER_CHALLENGE_PARTICIPANT_ERROR", error: error };
}

export function getActiveChallenge() {
    return { type: "GET_ACTIVE_CHALLENGE"};
}

export function getFinishedChallenge(id) {
    return { type: "GET_FINISHED_CHALLENGE", id: id };
}

export function reportScore(id, score) {
    return { type: "REPORT_SCORE", id: id, score: score };
}

export function createPrivateChallenge(challenge) {
    return { type: "CREATE_PRIVATE_CHALLENGE", challenge: challenge };
}

export function getPrivateChallenge(challengeId) {
    return { type: "GET_PRIVATE_CHALLENGE", challengeId: challengeId };
}

export function editPrivateChallenge(challengeId, challenge) {
    return { type: "EDIT_PRIVATE_CHALLENGE", challengeId: challengeId, challenge: challenge };
}

export function deletePrivateChallenge(challengeId) {
    return { type: "DELETE_PRIVATE_CHALLENGE", challengeId: challengeId };
}