import React from 'react';
import { connect } from 'react-redux';
import { joinDialog, goToRegisterDialog, wgcTournamentDialog, specChallengeTrackmanDialog } from "./challengeDialogs";
import { leave } from "../actions/challengeActions";
import { Link } from 'react-router-dom';
import ChallengeList from './challengeList';
import { goToRegister } from "../actions/dialogActions";
import { signup, goto } from "../actions/challengeActions";


var hsImage2 = { backgroundImage: 'url(/images/monthly-championship.jpg)', backgroundSize: 'cover', backgroundPosition: 'left center', height: 'inherit', borderRadius: '10px' }
var imgStyle = { top: "110px", left: "0px", fontSize: "1.3em" }
//var hsImage2 = { backgroundImage: "url(/images/weekend_championship_image.jpg)", backgroundSize: 'cover', backgroundPosition: 'left top' }

class SpecialChallenges extends React.Component {

    constructor(props) {
        super(props);

    }   

    componentDidMount() {

    }

    render() {
        const { specialChallenges, isLoggedIn, user, signup, goToRegister, localLeave, localGoTo, localSignup, localGoToRegister} = this.props;

        const special1 = specialChallenges && specialChallenges.length > 0 ? findArrayElementByType(specialChallenges, "SPECIAL_1") : null;
        const special2 = specialChallenges && specialChallenges.length > 0 ? findArrayElementByType(specialChallenges, "SPECIAL_2") : null;
        
        return (
            <div>
                {/* <div className="tournament tournament-1 boxChallengeList">
                    <Link to="/leaderboard">
                    <div className="challenge">
                        <div className="challenge-top">
                            <h2>Drappier Leaderboard<br />
                            <small>Coming in September</small>
                            </h2>
                            
                        </div>´
                                      
                    </div>
                    </Link>
                </div> */}
                
                {/* {specialChallengesArray(specialChallenges)}  */}
                <ChallengeList title='Special Challenges' color='green' challenges={specialChallenges} isLoggedIn={isLoggedIn} goto={localGoTo} signup={localSignup} goToRegister={localGoToRegister} />
                {/* <Link to="/lobby/3648">
                <div className="tournament tournament-2 boxChallengeList" style={{ background: "transparent" }}>
                    
                    <div className="challenge challenge-ad link-outside" style={hsImage2}>
                        <div className="challenge-top no-fade">
                           
                        </div>
                    </div> 
                    </div> 
                    </Link> */}
                    {/*
                    <div className="challenge challenge-ad challenge-ad2 challenge-click link-outside" style={hsImage} onClick={() => wgcTournamentDialog()}>
                        <div className="challenge-top no-fade">
                            
                            <p className="challenge-text"><p><span className="smalltext">TOURNAMENT</span></p> <p>World Golf Challenge</p> <p><span className="regulartext">9, 15-23 October</span></p></p>
                           
                        </div>
                    </div>
                    */}
                    {/*
                    <div className="challenge challenge-ad challenge-ad2 challenge-click link-outside" style={hsImage2} onClick={() => specChallengeWeekendDialog()} >
                        <div className="challenge-top no-fade">
                            <p className="challenge-text-bottom"><span>Weekend Championship</span> April 1st to May 31st!</p>
                        </div>
                    </div>
                    */}
               
                

                
            </div>
          
        );
    }
}

const loaded = ({ challengeName, special, isLoggedIn, signup, goToRegister, localLeave }) => {

    const additionalText = "<p>" + challengeName + " is a tournament where everyone competes against everyone!<br>Signup and await for it to begin! ETA for " + challengeName + " to start is 2020-06-25</p>";

    if (isLoggedIn) {
               
        return (
            <div className="buy-in-button" onClick={() => special.isParticipating ? localLeave(special.id) : joinDialog({ challengeName, additionalText, fee: '$1', pricePot: special.pricePot, prices: '', signup })} >
                <div className={challengeName === 'Crazy Crash' ? "price-pot small" : "price-pot large"}>
                    {'$' + special.pricePot}
                </div>
                {special.isParticipating ? 'LEAVE' : 'SIGN UP $1'} 
            </div>
        );
    }
    else {
        return (
            <div className="buy-in-button" onClick={() => goToRegisterDialog({ challengeName, additionalText, fee: '$1', pricePot: special.pricePot, prices: '', goToRegister })}>
                <div className={challengeName === 'Crazy Crash' ? "price-pot small" : "price-pot large"}>
                    {'$' + special.pricePot}
                </div>
			    SIGN UP $1
            </div>
        );
    }
}


const notLoaded = ({ challengeName }) => {
    return (
        <div className="buy-in-button">
            <div className={challengeName === 'Crazy Crash' ? "price-pot small": "price-pot large"}>
                ...
            </div>
			SIGN UP $1
        </div>
    );
}

function findArrayElementByType(array, type) {
    return array.find((element) => {
        return element.type === type;
    })
}

function mapStateToProps(state) {
    const specialChallenges = state.challengeReducer.specialChallenges;

    return {
        specialChallenges
    };
}
function mapDispatchToProps(dispatch) {
    return {
        //function
        localLeave: (id) => dispatch(leave(id, true)),
        localGoToRegister: (id) => dispatch(goToRegister(id)),
        localSignup: (id,special) => dispatch(signup(id,special)),
        localGoTo: id => dispatch(goto(id)),
    };
}


const specialChallenges = connect(mapStateToProps, mapDispatchToProps)(SpecialChallenges);
export { specialChallenges as SpecialChallenges }; 