const initialState = {
    leaderboard: {
        monthlySingleStablefordFreeLeaderboard: [],
        monthlySingleStablefordFreeLeaderboardEnds: null,
        monthlySingleStablefordFreePrices: [],
        monthlySingleStablefordFreeScore: 0,
        monthlySingleStablefordFreeLeader: {},
        monthlySingleStablefordFreePosition: 0,

        monthlySingleStablefordPayedSponsoredLeaderboard: [],
        monthlySingleStablefordPayedSponsoredLeaderboardEnds: null,
        monthlySingleStablefordPayedSponsoredPrices: [],
        monthlySingleStablefordPayedSponsoredScore: 0,
        monthlySingleStablefordPayedSponsoredLeader: {},
        monthlySingleStablefordPayedSponsoredPosition: 0,

        //monthlySingleStrokePlayLeaderboard: [],
        //monthlySingleStrokePlayLeaderboardEnds: null,
        //monthlySingleStrokePlayPrices: [],
        //monthlySingleStrokePlayScore: 0,
        //monthlySingleStrokePlayLeader: {},
        //monthlySingleStrokePlayPosition: 0,

        monthly1_18FreeLeaderboard: [],
        monthly1_18FreeLeaderboardEnds: null,
        monthly1_18FreePrices: [],
        monthly1_18FreeScore: 0,
        monthly1_18FreeLeader: {},
        monthly1_18FreePosition: 0,

        monthly1_18PayedSponsoredLeaderboard: [],
        monthly1_18PayedSponsoredLeaderboardEnds: null,
        monthly1_18PayedSponsoredPrices: [],
        monthly1_18PayedSponsoredScore: 0,
        monthly1_18PayedSponsoredLeader: {},
        monthly1_18PayedSponsoredPosition: 0,



        monthly19_36FreeLeaderboard: [],
        monthly19_36FreeLeaderboardEnds: null,
        monthly19_36FreePrices: [],
        monthly19_36FreeScore: 0,
        monthly19_36FreeLeader: {},
        monthly19_36FreePosition: 0,

        monthly19_36PayedSponsoredLeaderboard: [],
        monthly19_36PayedSponsoredLeaderboardEnds: null,
        monthly19_36PayedSponsoredPrices: [],
        monthly19_36PayedSponsoredScore: 0,
        monthly19_36PayedSponsoredLeader: {},
        monthly19_36PayedSponsoredPosition: 0,

        trackmanSingleLeaderboard: [],
        trackmanSingleLeaderboardEnds: null,
        trackmanSinglePrices: [],

        trackman1_18Leaderboard: [],
        trackman1_18LeaderboardEnds: null,
        trackman1_18Prices: [],

        trackman19_36Leaderboard: [],
        trackman19_36LeaderboardEnds: null,
        trackman19_36Prices: [],


        mostPlayedFree: [],
        mostPlayedPayedSponsored: [],


        privateLeaderboards: []
    }
};

export function leaderboardReducer(state = initialState, action) {
    switch (action.type) {
        case "GENERAL_LEADERBOARD_INFO":
            return {
                ...state,
                leaderboard: {
                    monthlySingleStablefordFreeLeaderboard: action.generalLeaderboard.monthlySingleStablefordFree.leaderboard,
                    monthlySingleStablefordFreeLeaderboardEnds: action.generalLeaderboard.monthlySingleStablefordFree.periodEnds,
                    monthlySingleStablefordFreePrices: action.generalLeaderboard.monthlySingleStablefordFree.prices,
                    monthlySingleStablefordFreeScore: action.generalLeaderboard.monthlySingleStablefordFree.userScore,
                    monthlySingleStablefordFreePosition: action.generalLeaderboard.monthlySingleStablefordFree.userPosition,
                    monthlySingleStablefordFreeLeader: action.generalLeaderboard.monthlySingleStablefordFree.leader,

                    monthlySingleStablefordPayedSponsoredLeaderboard: action.generalLeaderboard.monthlySingleStablefordPayedSponsored.leaderboard,
                    monthlySingleStablefordPayedSponsoredLeaderboardEnds: action.generalLeaderboard.monthlySingleStablefordPayedSponsored.periodEnds,
                    monthlySingleStablefordPayedSponsoredPrices: action.generalLeaderboard.monthlySingleStablefordPayedSponsored.prices,
                    monthlySingleStablefordPayedSponsoredScore: action.generalLeaderboard.monthlySingleStablefordPayedSponsored.userScore,
                    monthlySingleStablefordPayedSponsoredPosition: action.generalLeaderboard.monthlySingleStablefordPayedSponsored.userPosition,
                    monthlySingleStablefordPayedSponsoredLeader: action.generalLeaderboard.monthlySingleStablefordPayedSponsored.leader,

                    //monthlySingleStrokePlayLeaderboard: action.generalLeaderboard.monthlySingleStrokePlay.leaderboard,
                    //monthlySingleStrokePlayLeaderboardEnds: action.generalLeaderboard.monthlySingleStrokePlay.periodEnds,
                    //monthlySingleStrokePlayPrices: action.generalLeaderboard.monthlySingleStrokePlay.prices,
                    //monthlySingleStrokePlayScore: action.generalLeaderboard.monthlySingleStrokePlay.userScore,
                    //monthlySingleStrokePlayPosition: action.generalLeaderboard.monthlySingleStrokePlay.userPosition,
                    //monthlySingleStrokePlayLeader: action.generalLeaderboard.monthlySingleStrokePlay.leader,

                    monthly1_18FreeLeaderboard: action.generalLeaderboard.monthly1_18Free.leaderboard,
                    monthly1_18FreeLeaderboardEnds: action.generalLeaderboard.monthly1_18Free.periodEnds,
                    monthly1_18FreePrices: action.generalLeaderboard.monthly1_18Free.prices,
                    monthly1_18FreeScore: action.generalLeaderboard.monthly1_18Free.userScore,
                    monthly1_18FreePosition: action.generalLeaderboard.monthly1_18Free.userPosition,
                    monthly1_18FreeLeader: action.generalLeaderboard.monthly1_18Free.leader,

                    monthly1_18PayedSponsoredLeaderboard: action.generalLeaderboard.monthly1_18PayedSponsored.leaderboard,
                    monthly1_18PayedSponsoredLeaderboardEnds: action.generalLeaderboard.monthly1_18PayedSponsored.periodEnds,
                    monthly1_18PayedSponsoredPrices: action.generalLeaderboard.monthly1_18PayedSponsored.prices,
                    monthly1_18PayedSponsoredScore: action.generalLeaderboard.monthly1_18PayedSponsored.userScore,
                    monthly1_18PayedSponsoredPosition: action.generalLeaderboard.monthly1_18PayedSponsored.userPosition,
                    monthly1_18PayedSponsoredLeader: action.generalLeaderboard.monthly1_18PayedSponsored.leader,


                    monthly19_36FreeLeaderboard: action.generalLeaderboard.monthly19_36Free.leaderboard,
                    monthly19_36FreeLeaderboardEnds: action.generalLeaderboard.monthly19_36Free.periodEnds,
                    monthly19_36FreePrices: action.generalLeaderboard.monthly19_36Free.prices,
                    monthly19_36FreeScore: action.generalLeaderboard.monthly19_36Free.userScore,
                    monthly19_36FreePosition: action.generalLeaderboard.monthly19_36Free.userPosition,
                    monthly19_36FreeLeader: action.generalLeaderboard.monthly19_36Free.leader,

                    monthly19_36PayedSponsoredLeaderboard: action.generalLeaderboard.monthly19_36PayedSponsored.leaderboard,
                    monthly19_36PayedSponsoredLeaderboardEnds: action.generalLeaderboard.monthly19_36PayedSponsored.periodEnds,
                    monthly19_36PayedSponsoredPrices: action.generalLeaderboard.monthly19_36PayedSponsored.prices,
                    monthly19_36PayedSponsoredScore: action.generalLeaderboard.monthly19_36PayedSponsored.userScore,
                    monthly19_36PayedSponsoredPosition: action.generalLeaderboard.monthly19_36PayedSponsored.userPosition,
                    monthly19_36PayedSponsoredLeader: action.generalLeaderboard.monthly19_36PayedSponsored.leader,                   

                    trackmanSingleLeaderboard: action.generalLeaderboard.trackmanSingle.leaderboard,
                    trackmanSingleLeaderboardEnds: action.generalLeaderboard.trackmanSingle.periodEnds,
                    trackmanSinglePrices: action.generalLeaderboard.trackmanSingle.prices,

                    trackman1_18Leaderboard: action.generalLeaderboard.trackman1_18.leaderboard,
                    trackman1_18LeaderboardEnds: action.generalLeaderboard.trackman1_18.periodEnds,
                    trackman1_18Prices: action.generalLeaderboard.trackman1_18.prices,

                    trackman19_36Leaderboard: action.generalLeaderboard.trackman19_36.leaderboard,
                    trackman19_36LeaderboardEnds: action.generalLeaderboard.trackman19_36.periodEnds,
                    trackman19_36Prices: action.generalLeaderboard.trackman19_36.prices,
                                      

                    mostPlayedFree: action.generalLeaderboard.mostPlayedFree,
                    mostPlayedPayedSponsored: action.generalLeaderboard.mostPlayedPayedSponsored,


                    privateLeaderboards: action.generalLeaderboard.privateLeaderboards
                    
                }
            }
        case "PERSONAL_POSITION":
            return {
                ...state,
                leaderboard: {
                    ...state.leaderboard,
                    monthlySingleStablefordFreeScore: action.personalPosition.monthlySingleStablefordFree.userScore,
                    monthlySingleStablefordFreePosition: action.personalPosition.monthlySingleStablefordFree.userPosition,


                  
                    monthlySingleStablefordPayedSponsoredScore: action.personalPosition.monthlySingleStablefordPayedSponsored.userScore,
                    monthlySingleStablefordPayedSponsoredPosition: action.personalPosition.monthlySingleStablefordPayedSponsored.userPosition,

                 

                   
                    monthly1_18FreeScore: action.personalPosition.monthly1_18Free.userScore,
                    monthly1_18FreePosition: action.personalPosition.monthly1_18Free.userPosition,
                   

               
                    monthly1_18PayedSponsoredScore: action.personalPosition.monthly1_18PayedSponsored.userScore,
                    monthly1_18PayedSponsoredPosition: action.personalPosition.monthly1_18PayedSponsored.userPosition,
                   


                  
                    monthly19_36FreeScore: action.personalPosition.monthly19_36Free.userScore,
                    monthly19_36FreePosition: action.personalPosition.monthly19_36Free.userPosition,
                  

                  
                    monthly19_36PayedSponsoredScore: action.personalPosition.monthly19_36PayedSponsored.userScore,
                    monthly19_36PayedSponsoredPosition: action.personalPosition.monthly19_36PayedSponsored.userPosition,
                    


              

                }
            }
        default:
            return state;
    }
}