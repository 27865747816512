import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router'
//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import configureStore, { history } from "./store/configureStore";
import App from './app'; 
import { config } from "./config/config";
import LogRocket from 'logrocket';
import "./i18nextConf";

LogRocket.init(config.logrocket.key);

const store = configureStore; 
window.store = store;

ReactDOM.render(
    <Suspense fallback="...">
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>
    </Suspense>,
    document.getElementById('root')
);
unregister();

//registerServiceWorker();