import React from 'react'
import Countdown from 'react-countdown';

function createDateAsUTC(date) {
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()));
}

const Completionist = () => <h3>Game is starting..</h3>;

const CompletionistStartGame = () => <h3>Challenge has started</h3>;
const CompletionistEndGame = () => <h3>Challenge is finished</h3>;

const Clock = ({ minutes, seconds }) => {
        // Render a countdown
        const sLength = (seconds + '').length;
        const mLength = (minutes + '').length;
        return <h3>{mLength < 2 ? '0' + minutes : minutes}:{sLength < 2 ? '0' + seconds : seconds}</h3>;  
};

const ExtendedDaysClock = ({ days, hours, minutes, seconds }) => {
    // Render a countdown
    const sLength = (seconds + '').length;
    const mLength = (minutes + '').length;
    const daysText = days > 0 ? days + 'd' : '';
    return <div>{daysText} {hours}h {mLength < 2 ? '0' + minutes : minutes}m {sLength < 2 ? '0' + seconds : seconds}s</div>;
};

const ExtendedHoursClock = ({ hours, minutes, seconds }) => {
    // Render a countdown
    const sLength = (seconds + '').length;
    const mLength = (minutes + '').length;
    return <div>{hours}h {mLength < 2 ? '0' + minutes : minutes}min {sLength < 2 ? '0' + seconds : seconds}sec</div>;
};


const TournamentClock = ({ days, hours, minutes, seconds }) => {
    // Render a countdown
    const sLength = (seconds + '').length;
    const mLength = (minutes + '').length;

    if (days > 0) {
        return <div>{days} days</div>;
    }
    else {
        return <div>{hours}h {mLength < 2 ? '0' + minutes : minutes}min {sLength < 2 ? '0' + seconds : seconds}sec</div>;
    }
  
};

export const CooldownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <Completionist />;
    } else {
        // Render a countdown
        return <Clock minutes={minutes} seconds={seconds}/>
    }
};

export const StartGameRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <CompletionistStartGame />;
    } else {
        // Render a countdown
        return <ExtendedDaysClock days={days} hours={hours} minutes={minutes} seconds={seconds} />
    }
};

export const EndGameRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <CompletionistEndGame />;
    } else {
        // Render a countdown
        return <ExtendedDaysClock days={days} hours={hours} minutes={minutes} seconds={seconds} />
    }
};

export const MonthlyWinnerRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <div>Now!</div>;
    } else {
        // Render a countdown
        return <ExtendedDaysClock days={days} hours={hours} minutes={minutes} seconds={seconds} />
    }
};

export const DailyWinnerRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <div>Now!</div>;
    } else {
        // Render a countdown
        return <ExtendedHoursClock hours={hours} minutes={minutes} seconds={seconds} />
    }
};

export const TournamentRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <div>Now!</div>;
    } else {
        // Render a countdown
        return <TournamentClock days={days} hours={hours} minutes={minutes} seconds={seconds} />
    }
};

export const CountdownTimer = ({ mykey, started, offset, renderer}) => {

    var dt = new Date(started);
    dt = createDateAsUTC(dt);
    dt.setSeconds(dt.getSeconds() + offset);
    console.log("Started=" + started);
    console.log("DT=" + dt);
    return <Countdown key={mykey} date={dt} renderer={renderer} />;
}

