export function getLeaderboard() {
    return { type: "GET_LEADERBOARD" };
}

export function getPersonalPosition() {
    return { type: "GET_PERSONAL_POSITION" };
}

export function getStatistics() {
    return { type: "GET_STATISTICS" };
}